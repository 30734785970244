import { translateTimeDurationStatus } from "src/components/Homes/DeviceDetails/deviceLastUpdatedUtil"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"

export function DeviceLastUpdated({
  online,
  lastHeardFromAt,
  timezone,
}: {
  online: boolean
  lastHeardFromAt?: string
  timezone: string
}) {
  const { t } = useTranslate()
  const clockType = useGetUser().clock_type

  if (!lastHeardFromAt) {
    return null
  }

  if (online) {
    const statusText = translateTimeDurationStatus({
      online: true,
      clockType,
      lastHeardFromAt,
      timezone,
      t,
    })
    return <div>{statusText}</div>
  }

  return <OfflineBanner lastHeardFromAt={lastHeardFromAt} timezone={timezone} />
}

export function OfflineBanner({
  lastHeardFromAt,
  timezone,
}: {
  lastHeardFromAt: string
  timezone: string
}) {
  const { t } = useTranslate()
  const clockType = useGetUser().clock_type

  const statusText = translateTimeDurationStatus({
    online: false,
    clockType,
    lastHeardFromAt,
    timezone,
    t,
  })
  return (
    <MBanner type="error" size="small">
      {statusText}
    </MBanner>
  )
}
